import { useState, useRef } from "react";
import { Heading, Button } from "cai-fusion";
import FileDisplay from "../components/symphonyFileDisplay";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";

import { useTemplate } from "../contexts/TemplateContext";
import { appendixFile } from "./resources/dehssresources";

const UnearnedIncome = () => {
    const textWidthElement = useRef(null);
    const [files, setFiles] = useState([]);

    const { isRunning, executeTemplate } = useTemplate();

    // These two functions are what I needed to do to not immediately navigate when uploading a file.
    const deleteFiles = (fileInd) => {
        let filesCopy = [...files];
        filesCopy = [...filesCopy.slice(0, fileInd), ...filesCopy.slice(fileInd + 1)]
        setFiles([...filesCopy]);
    }

    const handleFileUpload = async (newFiles) => {
        setFiles([...files, ...newFiles]);
        console.log("[TEMPLATE] Added files. New file list:", ([...files], [...newFiles]));
    }

    // Function called when the "Send Message" button is hit.
    const handleSubmit = async () => {
        let overridePrompt = `
I have attached an Unearned Income document that I need to extract specific details from.
Please extract and display the following information:
    - Name
    - Income Type and Description
    - Pay Frequency (reference appendix A.1)
    - Income Begin Date
    - Payment Date
    - Gross Income Amount`;
let prompt = `
${overridePrompt}

If there is more than one, please do it for each.
Please show your work, your thought process, and any assumptions made in the process. So that a human auditor could follow your work and verify the results. Thank you!`;
        executeTemplate([...files, appendixFile], [prompt], [overridePrompt]);
    }

    return (
        <>
            <Heading size={3}>Let's get started!</Heading>
            <p className="a-muted-text">
                To begin the Unearned Income  Calculation, please drag and drop or upload an image of the unearned income document below. We’ll walk you through the rest!
            </p>
            <form action="" className="o-nl-form l-mt-sm">
                {/* <p className="o-nl-form__text">
                    1. Login to ASSIST WW to access application information.
                </p>
                <p className="o-nl-form__text">
                    2. Login to Outlook.
                </p>
                <p className="o-nl-form__text">
                    3. Login to OnBase.
                </p>
                <p className="o-nl-form__text">
                    4. Upload or drag image here.
                </p> */}
                <FileDisplay filesOverride={files} deleteOverride={deleteFiles} />
                <div style={{ marginBottom: "20px" }}>
                    <DnDFileUpload
                        notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1',
                            '.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx',
                            '.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
                            '.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
                        overwriteHandleFiles={handleFileUpload}
                    />
                </div>
                <div className="m-button-row">
                    <Button onClick={handleSubmit} disabled={files.length === 0 || isRunning}>
                        Calculate
                    </Button>
                </div>
            </form>
            <span id="text-width" ref={textWidthElement}>
                Error Message or Unexpected Result And More Length
            </span>
        </>
    );
};

export default UnearnedIncome;