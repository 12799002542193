import { createContext, useState, useContext, useEffect } from "react";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";

const UserProfileContext = createContext();

function UserProfileProvider({ children }) {
	const apiServiceClient = useSymphonyApiService("v2");

    const [userProfile, setUserProfile] = useState({});

    const [defaultChatSettings, setDefaultChatSettings] = useState([]);
    const [defaultAssistant, setDefaultAssistant] = useState(null);
    const [defaultModel, setDefaultModel] = useState(null);
    
    // Fetch the user profile and update the state. Create user profile on the API end if it's not found.
    useEffect(() => {
        const getUserProfile = async () => {
            try {
                console.log("[USERPROFILE] Fetching user profile...");
                let profile = await apiServiceClient.UserProfiles.getLoggedInUserProfile(["defaultmodelparameter", "assistantinstruction"])

                // If there is no profile or the call errors, attempt to create a new profile.
                if(!profile){
                    console.log("[USERPROFILE] No profile found. Creating a new one...");
                    let newProfile = await apiServiceClient.UserProfiles.createUserProfile()
                    setUserProfile(newProfile ?? {});
                }
                else {
                    console.log("[USERPROFILE] Profile found! Here it is:", profile);
                    setUserProfile(profile);
                    apiServiceClient.UserProfiles.syncLoggedInUserProfile();
                }
            }
            catch(e) {
                console.log("[USERPROFILE] Error when fetching profile. Creating a new one...");
                let newProfile = await apiServiceClient.UserProfiles.createUserProfile()
                setUserProfile(newProfile ?? {});
            }
        }

        getUserProfile();
    }, [])

    // Updating the Symphony-based fields for the user profile.
    useEffect(() => {
        const updateProfileDetails = async () => {
            if (!userProfile) return;

            // Fetch the settings for the user
            console.log("[USERPROFILE] Grabbing user's chat settings...");
            let settings = userProfile.defaultModelParameters;
            setDefaultChatSettings(settings ?? []);
    
            // Fetch the default assistant for the user
            console.log("[USERPROFILE] Grabbing user's default assistant...");
            let assistant = userProfile.defaultAssistantInstruction
            setDefaultAssistant(assistant ?? {});
        }

        updateProfileDetails();
    }, [userProfile, setDefaultChatSettings, setDefaultAssistant])

    // Update user profile with changes
    const updateUserProfile = async (updatedUserProfile) => {
        if (!updatedUserProfile || !updatedUserProfile.userProfileId) return;

        // When updating the user profile, the default model parameters were not brought on correctly.
        // This is because JavaScript is a wonderful language that functions exactly how you intend it to.
        // This appends them if need be.
        const totalUserProfile = {...updatedUserProfile, defaultModelParameters: [], defaultAssistantInstruction: null}
        // if (!totalUserProfile.defaultModelParameters) {
        //     totalUserProfile.defaultModelParameters = [...defaultChatSettings];
        // }

        console.log("[USERPROFILE] Updating user profile...");
        await apiServiceClient.UserProfiles.updateUserProfile(totalUserProfile.userProfileId, totalUserProfile);
        setUserProfile(updatedUserProfile);
    };

    // Helper function to generate a user's name in a readable way. 
    const compileNameHelper = (userProfile) => {
        if (!userProfile) {
            return "";
        }
        return `${userProfile.preferredName ?? userProfile.givenName} ${userProfile.familyName}`
    }

	return (
		<UserProfileContext.Provider
			value={{
				// States
                userProfile,
                setUserProfile,
                defaultAssistant,
                setDefaultAssistant,
                defaultChatSettings,
                setDefaultChatSettings,
                defaultModel,
                setDefaultModel,
                // Functions
                updateUserProfile,
                compileNameHelper,
			}}
		>
			{children}
		</UserProfileContext.Provider>
	);
}

// Hook to use the UserProfileContext in a component
function useUserProfile() {
	const context = useContext(UserProfileContext);
	if (context === undefined) {
		throw new Error(
			"useUserProfile must be used within a UserProfileProvider"
		);
	}
	return context;
}

export { UserProfileProvider, useUserProfile };
