import { useEffect, useState, useRef } from "react";

// import "./ChatEntry.css";
import { useUserProfile } from "../../symphony/contexts/UserProfileContext";

import ChatEntryEdit from "./ChatEntry/ChatEntryEdit";
import ChatEntryMsg from "./ChatEntry/ChatEntryMsg";
import ChatEntryAction from "./ChatEntry/ChatEntryAction";
import TimeAgo from "../TimeAgo/TimeAgo";


const ChatBubble = ({
	messageId,
	messagePairId,
	role,
	content,
	statusMessage,
	IconElement,
	isLoading,
	chatEntryActions,
	userProfileId,
	userProfileImages,
	isEditing,
	setEditingMessageId,
	resendMessage,
	timestamp,
	displayName,
}) => {
	const lottieRef = useRef();
	const [userIcon, setUserIcon] = useState(null);

	const [editedMessage, setEditedMessage] = useState(
		content
			.filter((contentItem) => contentItem.type === "text")
			.sort((a, b) => a.contentOrder - b.contentOrder)
			.map((contentItem) => contentItem.text)
			.join("\n")
	);

	const { userProfile } = useUserProfile();

	useEffect(() => {
		if (!lottieRef.current) {
			return;
		}
		if (typeof lottieRef.current.play() === "function") {
			return;
		}
		if (isLoading) {
			lottieRef?.current?.play();
		} else {
			lottieRef?.current?.stop();
		}
	}, [isLoading, IconElement]);

	const handleResubmit = () => {
		resendMessage(messageId, editedMessage);
		setEditingMessageId(null);
	};

	const isUser = userProfileId === userProfile.userProfileId
	const isReply = (role === "assistant" || role === "error" || !isUser);

	let classes = "m-chat-bubble"
	if (isReply) classes += " m-chat-bubble--reply"
	if (role === "assistant") classes += " m-chat-bubble--ai"
	if (role === "error") classes += " m-chat-bubble--error"

	return (
		<div className={classes}>
			{ isReply &&
			<div className="m-chat-bubble__avatar-wrapper">
				<div className="a-avatar">
					<div className="a-avatar__img a-avatar__img--lottie">
						{IconElement && IconElement(lottieRef, userProfileId)}
					</div>
				</div>
			</div> }
			{isEditing ? (
				<div className="m-chat-bubble__message-wrapper">
					<ChatEntryEdit 
						editedMessage={editedMessage}
						setEditedMessage={setEditedMessage}
						handleResubmit={handleResubmit}
						setEditingMessageId={setEditingMessageId}
					/>
				</div>
			) : (
				<div className="m-chat-bubble__message-wrapper">
					<div className="m-chat-bubble__message-inner">
						{isLoading ? (
							<div className="m-chat-bubble__message">
								<div className="a-skeleton a-skeleton--line l-mb-xs"></div>
								<div className="a-skeleton a-skeleton--line l-mb-xs"></div>
								<div className="a-skeleton a-skeleton--line l-mb-xs"></div>
							</div>
						) : (
							<div className="m-chat-bubble__message">
								
								<ChatEntryMsg content={content} />
								{/* <p><TimeAgo timestamp={timestamp} limit={3} limitUnit="day"/> {timestamp}</p> */}
							
							</div>
						)}
						<div className="m-chat-bubble__actions">
							<div className="row">
								<div className="col-md-6 h-vert-center-col">
									{statusMessage && (
										<p className="m-chat-bubble__status-msg">
											{statusMessage}
										</p>
									)}
								</div>
								<div className="col-md-6">
									{isLoading ? (
										<div className="m-icon-row m-icon-row--right-align m-icon-row--condensed">
											{/* one placeholder for each action mapped to the type of entry */}
											{chatEntryActions.map(
												(action, index) => (
													<div
														key={index}
														className="a-skeleton a-skeleton--link-icon m-icon-row__icon"
													></div>
												)
											)}
										</div>
									) : (
										<div className="m-icon-row m-icon-row--right-align m-icon-row--condensed">
											{chatEntryActions.map(
												(action, index) => (
													<ChatEntryAction
														key={index}
														index={index}
														{...action}
														messageId={messageId}
														messagePairId={
															messagePairId
														}
														// TODO, include image description?
														message={content
															.filter(
																(contentItem) =>
																	contentItem.type ===
																	"text"
															)
															.sort(
																(a, b) =>
																	a.contentOrder -
																	b.contentOrder
															)
															.map(
																(contentItem) =>
																	contentItem.text
															)
															.join("\n")}
													/>
												)
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="m-chat-bubble__meta-wrapper">
						{ isReply && <p className="m-chat-bubble__speaker">{isUser ? "You" : displayName}</p>}
						<p className="m-chat-bubble__timestamp">
							<TimeAgo timestamp={timestamp} limit={3} limitUnit="day"/>
						</p>
						{ !isReply && <p className="m-chat-bubble__speaker">{isUser ? "You" : displayName}</p>}
					</div>
				</div>
			)}
			{ !isReply &&
			<div className="m-chat-bubble__avatar-wrapper">
				<div className="a-avatar">
					<div className="a-avatar__img a-avatar__img--lottie">
						{IconElement && IconElement(lottieRef, userProfileId)}
					</div>
				</div>
			</div> }
		</div>
	);
};

export default ChatBubble;
