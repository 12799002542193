import React, { createContext, useState, useContext, useEffect } from "react";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";

const LicensingContext = createContext();

function LicensingProvider({ children }) {
	const apiServiceClient = useSymphonyApiService();

    const [isLicensedEnv, setIsLicensedEnv] = useState(false);
    const [hasLicense, setHasLicense] = useState(true);

    const loadLicenseDetails = () => {
        apiServiceClient.Licensing.isLicensedEnvironment().then((res) => setIsLicensedEnv(res === "true" || res === true));
		apiServiceClient.Licensing.amILicensed().then((res) => {
			setHasLicense(res === false || res === "false" ? false : true)
		});
		console.log("[LICENSE] Am I Licensed?", hasLicense, "Is the Environment?", isLicensedEnv);
    }

    useEffect(() => {
        loadLicenseDetails();
    }, []);

	return (
		<LicensingContext.Provider
			value={{
				isLicensedEnv,
				hasLicense,
			}}
		>
			{children}
		</LicensingContext.Provider>
	);
}

// Hook to use the LicensingContext in a component
function useLicensing() {
	const context = useContext(LicensingContext);
	if (context === undefined) {
		throw new Error("useLicensing must be used within a LicensingProvider");
	}
	return context;
}

export { LicensingProvider, useLicensing };