import { useState } from "react";
import { Heading, Button } from "cai-fusion";
import FileDisplay from "../components/symphonyFileDisplay";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";

import { useTemplate } from "../contexts/TemplateContext";
import { appendixFile } from "./resources/dehssresources";

const LumpSumIncomeCalculation = () => {
	const LumpSumIncomeCalculationOverridePrompt = [
`I have attached a Lump Sum Income statement that I need to extract specific details from.
Please extract and display the following information:
    - Name
    - Lump Sum Type
    - Date received
    - Gross Income Amount`
	];

    let LumpSumIncomeCalculationPrompt = [
`${LumpSumIncomeCalculationOverridePrompt}

If there is more than one, please do it for each.
Please show your work, your thought process, and any assumptions made in the process. So that a human auditor could follow your work and verify the results. Thank you!`];

	const { isRunning, executeTemplate } = useTemplate();
	const [files, setFiles] = useState([]);

	const deleteFiles = (fileInd) => {
		let filesCopy = [...files];
		filesCopy = [...filesCopy.slice(0, fileInd), ...filesCopy.slice(fileInd + 1)]
		setFiles([...filesCopy]);
	}

	const handleUploads = async () => {
		executeTemplate([...files, appendixFile], [...LumpSumIncomeCalculationPrompt], [...LumpSumIncomeCalculationOverridePrompt]);
	}
	const handleFileUpload = async (newFiles) => {
		setFiles([...files, ...newFiles]);
		console.log("[TEMPLATE] Added files. New file list:", ([...files], [...newFiles]));
	}

	return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To begin the lump sum income calculation, please drag and drop or paste the image below. We'll walk you through the rest.
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				{/* <p className="o-nl-form__text">
					1. Login to ASSIST WW to input application info
				</p>
				<p className="o-nl-form__text">
					2. Login to Outlook
				</p>
				<p className="o-nl-form__text">
					3. Login to OnBase
				</p>
				<p className="o-nl-form__text">
					4. Upload or drag image here.
				</p> */}
				<FileDisplay filesOverride={files} deleteOverride={deleteFiles} />
				<div style={{ marginBottom: "20px" }}>
					<DnDFileUpload
						notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1',
							'.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx',
							'.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
							'.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
						overwriteHandleFiles={handleFileUpload}
					/>
				</div>
				<div className="m-button-row">
					<Button onClick={handleUploads} disabled={files.length === 0 || isRunning}>
                        Calculate
					</Button>
				</div>
			</form>
		</>
	);
};

export default LumpSumIncomeCalculation;