import { useState } from 'react';
import { ReactComponent as IconCancel } from '../../../../images/icon-cancel.svg';
import { useMessages } from '../../../symphony/contexts/MessageContext';
import { SimpleToolTip } from '../../ToolTips';

const ChatEntryAction = ({
	index,
	alt,
	options,
	onClick,
	messageId,
	messagePairId,
	message,
	icon: ActionIcon,
}) => {
	const [menuShown, setMenuShown] = useState(false);
	const [active, setActive] = useState(false);
	const { regenerateMessage } = useMessages;

	return (
		// if has options instead, override the click function and do things differently
		<SimpleToolTip
			key={index}
			refElement={
				options && options.length > 0 ? (
					<div
						className={`o-button-context-menu${
							menuShown && " o-button-context-menu--shown"
						}`}
					>
						<button
							key={index}
							className="a-icon-link m-icon-row__icon"
							aria-label={alt}
							onClick={(e) => {
								e.preventDefault();
								setMenuShown(!menuShown);
							}}
						>
							<div className="a-icon-link__inner">
								<div className="a-icon-link__icon-wrapper">
									{menuShown ? (
										<IconCancel className="a-icon-link__icon" />
									) : (
										<ActionIcon className="a-icon-link__icon" />
									)}
								</div>
							</div>
						</button>
						<ul className="m-context-menu o-button-context-menu__menu">
							{options.map((option, index) => (
								<li className={option.className} key={index}>
									<button
										className={option.buttonClassName}
										disabled={option.isDisabled}
										data-modal-target={option.modalTarget}
										data-drawer-target={option.drawerTarget}
										onClick={(e) => {
											e.preventDefault();
											if (option.actionFunction) {
												option.actionFunction(
													messageId,
													messagePairId,
													message,
													regenerateMessage
												);
											}
										}}
									>
										<div className="a-icon-link__inner">
											<div className="a-icon-link__icon-wrapper">
												{option.IconElement}
											</div>
											<span className="a-icon-link__text">
												{option.text}
												{option.helpText && (
													<span className="a-icon-link__help-text">
														{option.helpText}
													</span>
												)}
											</span>
										</div>
									</button>
								</li>
							))}
						</ul>
					</div>
				) : (
					// otherwise, be a normal button
					<button
						key={index}
						className={`a-icon-link m-icon-row__icon${
							active ? " a-icon-link--active" : ""
						}`}
						aria-label={alt}
						onClick={(e) => {
							e.preventDefault();
							if (onClick) {
								onClick(
									messageId,
									messagePairId,
									message,
									regenerateMessage
								);
							}
						}}
					>
						<div className="a-icon-link__inner">
							<div className="a-icon-link__icon-wrapper">
								<ActionIcon className={`a-icon-link__icon`} />
							</div>
						</div>
					</button>
				)
			}
			text={alt}
		/>
	);
};

export default ChatEntryAction;