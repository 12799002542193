import { useState, useEffect, useContext } from "react";
import { ReactComponent as IconCloseSquare } from "../../../images/close-square.svg";
import { ReactComponent as IconTrash } from "../../../images/icon-trash.svg"
import { ReactComponent as IconAdd } from "../../../images/icon-add.svg"

import ImageSearch from "../../../images/search-icon-light.png"

import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";
import FileDisplay from "../components/symphonyFileDisplay";
import { Drawer, HeadingRow, Pagination } from "cai-fusion";
import { useResource } from "../contexts/ResourceContext";
import { useMessages } from "../contexts/MessageContext";
import { useSettings } from "../contexts/SettingsContext";
import { ToastContext } from "cai-fusion";
import { useDataStore } from "../contexts/DataStoreContext";

const DataSourceDrawer = (props) => {
    const { dataSources, enabledDataStores, setEnabledDataStores, handleDataSourcesChange, handleFilesUpload, uploadComplete, setUploadComplete, enableDataStore, disableDataStore } = useResource();
    const { requestedTokens } = useMessages();
    const { tokenLimit } = useSettings();
    const [tokenUsage, setTokenUsage] = useState(0.0);
    const [maxTokens, setMaxTokens] = useState(false);
    const [browsingData, setBrowsingData] = useState(false);
    const { createToast } = useContext(ToastContext);
    const { dataStores, allDataStores, searchDataStores } = useDataStore();

    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);
    const [disabledDataStores, setDisabledDataStores] = useState([]);

    const closeClickedHandler = () => {
        if (props.onClose) props.onClose();
    };

    useEffect(() => {
        setDisabledDataStores(dataStores.filter((dataStore) => !enabledDataStores.find((enabledDataStore) => enabledDataStore.id === dataStore.id)));
    }, [dataStores, enabledDataStores])

    useEffect(() => {
        // A check to update pagination as needed - if we're on a page that no longer exists, go back one.
        if (page > 1 && disabledDataStores.length <= (page - 1) * 10) {
            setPage(page - 1);
        }
    }, [disabledDataStores])

    useEffect(() => {
        // Close the modal if the upload was successful
        if (uploadComplete) {
            closeClickedHandler();
            setUploadComplete(false);
        }
    }, [uploadComplete]);

    useEffect(() => {
        if (tokenLimit > 1) {
            const percent = Math.floor((requestedTokens / tokenLimit) * 100);
            setTokenUsage(percent);
            setMaxTokens(percent >= 99.98);
        }
    }, [requestedTokens, tokenLimit]);

    // Effect for creating a toast when maxTCHit is set to true
    useEffect(() => {
        if (maxTokens) {
            const toastMessage = (
                <>
                    <h4>Max Memory Reached</h4>
                    <div>I have so many memories of us chatting! Start a new chat or remove some files.</div>
                </>
            );
            createToast(toastMessage, 'info');
        }
    }, [maxTokens, createToast]);

    return (
        <Drawer
            clickOutsideToClose
            isHidden={props.isHidden}
            id="data-sources-drawer"
            onClose={() => closeClickedHandler()}
            header={
                <HeadingRow
                    title="Data Sources"
                    eyebrow="Settings"
                    size={3}
                >
                    <div className="m-icon-row m-icon-row--right-align">
                        <a
                            id="drawerCloseBtn"
                            className="a-icon m-icon-row__icon"
                            title="Close Associate Details"
                            onClick={props.onClose}
                        >
                            <IconCloseSquare className="a-icon-link__icon">
                                {" "}
                            </IconCloseSquare>
                        </a>
                    </div>
                </HeadingRow>
            }
        >
            <form className="o-form l-mt-sm">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-12">
                                <h5 className="a-heading a-heading--heading-3">
                                    Data Collections
                                </h5>
                                <p className="a-muted-text">Include pre-defined collections of related content that provide context for the assistant. These can include files, websites and more. Data collections can be used across different chats.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="o-block">
                                    <table className="o-treegrid o-treegrid--compact l-mr-sm">
                                        <colgroup className="o-treegrid__colgroup">
                                            <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
                                            <col className="o-treegrid__col o-treegrid__col--32" span="1" />
                                        </colgroup>
                                        <tbody>
                                            {enabledDataStores.map((enabledDataStore) => {
                                                return (
                                                    <tr className="o-treegrid__row o-treegrid__row--shown" key={enableDataStore.name}>
                                                        <td className="o-treegrid__cell"><span className="a-subtle-text"><strong>{enabledDataStore.name}</strong></span></td>
                                                        <td className="o-treegrid__cell o-treegrid__cell--right-aligned">
                                                            <button className="a-icon-link a-icon-link--destructive" onClick={(e) => { e.preventDefault(); disableDataStore(enabledDataStore) }}>
                                                                <div className="a-icon-link__inner">
                                                                    <div className="a-icon-link__icon-wrapper">
                                                                        <IconTrash className="a-icon-link__icon"/>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div className={`m-collapsible-panel m-collapsible-panel--shaded l-mt-xs ${ browsingData ? "m-collapsible-panel--expanded" : ""}`}>
                                        <a className="m-collapsible-panel__heading" aria-expanded={browsingData ? "true": "false" }>
                                            <h3 className="a-heading a-heading--heading-5 m-collapsible-panel__heading-label">Browse additional collections to add</h3>
                                            <span className="m-collapsible-panel__collapse-toggle" onClick={(e) => { e.preventDefault(); setBrowsingData(!browsingData); }}>
                                                <svg className="m-collapsible-panel__collapse-icon" xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08" aria-hidden={browsingData ? "false": "true"}>
                                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-6.46 -6.46)">
                                                        <g id="Layer_1" data-name="Layer 1">
                                                            <rect className="m-collapsible-panel__collapse-icon-horizontal" id="Rectangle_45" data-name="Rectangle 45" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect>
                                                            <rect className="m-collapsible-panel__collapse-icon-vertical" id="Rectangle_46" data-name="Rectangle 46" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </a>
                                        <div className="m-collapsible-panel__contents">
                                            <div className="row">
                                                <div className="col-12">
                                                    <input className="a-search-input__text-box" type="text" placeholder="Search collections..." aria-label="Search" id="search_field" name="search_field" onChange={(e) => setSearchText(e.target.value)}/>
                                                    <button className="a-search-input__button" type="submit" onClick={(e) => {e.preventDefault(); searchDataStores(searchText);}}>
                                                        <img className="a-search-input__img" src={ImageSearch} alt="search button icon" />
                                                    </button>
                                                    <table id="sharedUsers" className="o-treegrid o-treegrid--compact l-mr-sm l-mt-xs">
                                                        <colgroup className="o-treegrid__colgroup">
                                                            <col className="o-treegrid__col o-treegrid__col--xxx-wide" span="1" />
                                                            <col className="o-treegrid__col o-treegrid__col--small" span="1" />
                                                        </colgroup>
                                                        <tbody>
                                                            {disabledDataStores.slice((page - 1) * 10, Math.min(disabledDataStores.length, page * 10)).map((dataStore) => {
                                                                    return (
                                                                        <tr className="o-treegrid__row o-treegrid__row--shown">
                                                                            <td className="o-treegrid__cell">
                                                                                <p className="a-subtle-text l-mb-none"><strong>{dataStore.name}</strong></p>
                                                                                <p className="a-subtle-text a-muted-text l-mt-none">{dataStore.description}</p>
                                                                            </td>
                                                                            <td className="o-treegrid__cell">
                                                                                <div className="m-button-row">
                                                                                    <button className="a-icon-link" onClick={(e) => { e.preventDefault(); enableDataStore(dataStore) }}>
                                                                                        <div className="a-icon-link__inner">
                                                                                            <div className="a-icon-link__icon-wrapper">
                                                                                                <IconAdd className="a-icon-link__icon" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                            <Pagination 
                                                                numPerPage={10}
                                                                data={disabledDataStores}
                                                                currentPage={page}
                                                                onPageChange={(page) => setPage(page)}
                                                            />
                                                        </tbody>
                                                    </table>
                                                    {/*<div className="m-pagination-row l-mt-xs">*/}
                                                    {/*    <span className="m-pagination-row__page m-pagination-row__page--disabled">❮</span>*/}
                                                    {/*    <a href="#" className="m-pagination-row__page m-pagination-row__page--current">1</a>*/}
                                                    {/*    <a href="#" className="m-pagination-row__page m-pagination-row__page--unselected">2</a>*/}
                                                    {/*    <a href="#" className="m-pagination-row__page m-pagination-row__page--unselected">3</a>*/}
                                                    {/*    <span className="m-pagination-row__page m-pagination-row__page--disabled">...</span>*/}
                                                    {/*    <a href="#" className="m-pagination-row__page m-pagination-row__page--unselected">7</a>*/}
                                                    {/*    <a href="#" className="m-pagination-row__page m-pagination-row__page--unselected">8</a>*/}
                                                    {/*    <a href="#" className="m-pagination-row__page m-pagination-row__page--unselected">9</a>*/}
                                                    {/*    <a href="#" className="m-pagination-row__page m-pagination-row__page--unselected">❯</a>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {(dataSources !== null && dataSources?.length !== 0) &&
                            <div className="row">
                                <div className="col-12">
                                    <div className="o-block">
                                            {dataSources?.map((source, index) => (
                                                <label className="a-checkbox" key={index}>
                                                    <span>{source.name}</span>
                                                    <input
                                                        checked={source.included ?? false}
                                                        type="checkbox"
                                                        onChange={() => handleDataSourcesChange(index)}
                                                    />
                                                    <div className="b-input"></div>
                                                </label>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="row l-mt-md">
                            <div className="col-md-12">
                                <h5 className="a-heading a-heading--heading-3">
                                    Web Pages
                                </h5>
                                <p className="a-muted-text">
                                    Provide the URLs of web pages you'd like Symphony to
                                    incorporate into the conversation. Note that Symphony
                                    can't crawl whole websites. It will only scan the
                                    individual pages you provide URLs for below.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="o-block">Coming Soon!</div>
                            </div>
                        </div>
                        <div className="row l-mt-md">
                            <div className="col-12">
                                <h5 className="a-heading a-heading--heading-3">
                                    Included Files
                                </h5>
                                <p className="a-muted-text">
                                    Documents that the assistant is able to reference for
                                    context in this chat.
                                </p>
                            </div>
                        </div>
                        <div className="row l-mb-sm">
                            <div className="col-12">
                                <div className="o-block">
                                    <FileDisplay />
                                </div>
                            </div>
                        </div>
                        <DnDFileUpload
                        // TODO - list maintined in hook/context?
                            notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1',
                                '.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx',
                                '.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
                                '.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
                            overwriteHandleFiles={handleFilesUpload}
                        />
                        <div className="row l-mt-md">
                            <div className="col-md-12">
                                <h5 className="a-heading a-heading--heading-3">Memory Usage</h5>
                                <p className="a-muted-text">
                                    Symphony can only hold so much information in memory for a
                                    given chat. Each file, website, and message use some of
                                    that memory. When the memory limit is reached, it's time
                                    to start a new chat or to remove files and web pages you
                                    no longer need.
                                </p>
                            </div>
                        </div>
                        <div className="l-row">
                            <div className="col-md-12">
                                <div className="m-progress-bar m-progress-bar--">
                                    <div
                                        className="m-progress-bar__inner"
                                        style={{ flexBasis: "100%" }}
                                    >
                                        <div className="m-progress-bar__progress-wrapper">
                                            <p className="m-progress-bar__annotation">{tokenUsage}%</p>
                                            <div
                                                className={`m-progress-bar__bar ${tokenUsage > 80 && tokenUsage < 100 ? 'm-progress-bar__bar--yellow' : tokenUsage >= 100 ? 'm-progress-bar__bar--red' : ''}`}
                                                style={{ width: `${tokenUsage > 100 ? 100 : tokenUsage}%` }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Drawer>
    );
}

export default DataSourceDrawer;
