import { HeadingRow } from "cai-fusion";
import { useEffect, useState } from "react";
import { useDataStore } from "../contexts/DataStoreContext";

import EditTextNoteDrawer from "../overlay/editTextNoteDrawer";

import { ReactComponent as IconAdd } from "../../../images/icon-add.svg";
import { ReactComponent as IconFileOutline } from "../../../images/icon-file-outline.svg"
import { ReactComponent as IconTrash } from "../../../images/icon-trash.svg";
import { ReactComponent as IconDraft } from "../../../images/icon-draft.svg";
import { ReactComponent as RippleLoading } from "../../../images/ripple-loading.svg";

const DataStoreTextNotes = () => {
    const [notes, setNotes] = useState([]);
    const [selectedNote, setSelectedNote] = useState({});
    const [hideEditNoteDrawer, setHideEditNoteDrawer] = useState(true);

    const { dataStore, addNoteToCurrentDataStore, deleteCurrentDataStoreContent, setDataStore } = useDataStore();
    
    useEffect(() => {
        setNotes(dataStore?.contentItems?.filter((item) => item.source === "text") || []);
    }, [dataStore])

    // Handle function that does both editing and creating a new text note.
    const handleSubmitNote = async (name, text) => {
        // Editing is essentially deleting the current one and adding in a new one.

        // Handling the visibility with states first...
        if (selectedNote) {
            setNotes((current) => [...current.filter((note) => note.id !== selectedNote.id)]);
        }
        setNotes((current) => [...current, ({"resourceName": name, "isLoading": true})]);

        // Deleting the old if we're editing...
        if (selectedNote) {
            await deleteCurrentDataStoreContent(selectedNote.id, true);
        }
        // ...and adding the new.
        let newNote = await addNoteToCurrentDataStore(name, text);
        if (newNote) {
            // TODO: Remove this set state in here. Bad bad bad.
            let newContentItems = [...dataStore.contentItems.filter((item) => item.id !== (selectedNote?.id ?? "")), newNote]
            setDataStore({...dataStore, contentItems: [...newContentItems]});
            // setNotes((current) => [...current, newNote]);
        }
        setSelectedNote({});
    }

    // Deletes a text note.
    const deleteTextNote = async (textNoteId) => {
        setNotes(notes.filter((note) => note.id !== textNoteId));
        await deleteCurrentDataStoreContent(textNoteId);
    }
    
    return (
        <>
            <HeadingRow
                className="m-heading-row--secondary"
                title="Text Notes"
                size={3}
            >
                <div className="m-icon-row m-icon-row--right-align">
                    <button 
                        className="a-icon-link m-icon-row__icon"
                        onClick={() => {setSelectedNote(undefined); setHideEditNoteDrawer(false);}}
                    >
                        <div className="a-icon-link__inner">
                            <div className="a-icon-link__icon-wrapper">
                                <IconAdd className="a-icon-link__icon" />
                            </div>
                            <span className="a-icon-link__text">Add Text Note</span>
                        </div>
                    </button>
                </div>
            </HeadingRow>
            <div className="row">
                <div className="col-12">
                    <div className="o-block">
                        { notes.length === 0 ?
                            <div className="col-md-12 text-center" aria-colspan={4}>
                                <p className="a-muted-text">You have no text notes for this data collection.</p>   
                            </div>
                        :
                            <table className="o-treegrid o-treegrid--compact l-mr-sm">
                                <colgroup className="o-treegrid__colgroup">
                                    <col className="o-treegrid__col o-treegrid__col--48" span={1} />
                                    <col className="o-treegrid__col o-treegrid__col--large" span={1} />
                                    <col className="o-treegrid__col o-treegrid__col--48" span={1} />
                                </colgroup>
                                <tbody>
                                    { notes.sort((a, b) => a.resourceName.localeCompare(b.resourceName)).map((note) => {
                                        return (
                                            <TextNoteItem 
                                                key={note.id ?? "tempname"}
                                                noteObj={note} 
                                                onEdit={() => {setSelectedNote(note); setHideEditNoteDrawer(false);}}
                                                onDelete={() => {setSelectedNote(note); deleteTextNote(note.id)}}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
            <EditTextNoteDrawer
                isHidden={hideEditNoteDrawer}
                onClose={() => setHideEditNoteDrawer(true)}
                selectedNote={selectedNote}
                onSubmit={(name, text) => {
                    setHideEditNoteDrawer(true);
                    handleSubmitNote(name, text);
                }}
            />
        </>
    )
}

const TextNoteItem = ({noteObj, onEdit, onDelete}) => {
    return (
        <tr className="o-treegrid__row o-treegrid__row--shown">
            <td className="o-treegrid__cell">
                <span className="a-icon">
                    { noteObj.isLoading ?
                        <RippleLoading className="a-icon__img" style={{maxHeight: 50}}/> :
                        <IconFileOutline className="a-icon__img" />
                    }
                </span>
            </td>
            <td className="o-treegrid__cell">
                <span className="a-subtle-text">
                    <strong>{noteObj.resourceName}</strong>
                </span>
            </td>
            <td className="o-treegrid__cell">
                { !noteObj.isLoading &&
                    <div className="m-button-row">
                        <button className="a-icon-link" onClick={onEdit}>
                            <div className="a-icon-link__inner">
                                <div className="a-icon-link__icon-wrapper">
                                    <IconDraft className="a-icon-link__icon" />
                                </div>
                            </div>
                        </button>
                        <button className="a-icon-link a-icon-link--destructive" onClick={onDelete}>
                            <div className="a-icon-link__inner">
                                <div className="a-icon-link__icon-wrapper">
                                    <IconTrash className="a-icon-link__icon" />
                                </div>
                            </div>
                        </button>
                    </div>
                }
            </td>
        </tr>
    )
}

export default DataStoreTextNotes;