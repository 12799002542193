import React, {useEffect, useRef } from 'react';

function doScroll(scrollSpot, scrollMargin = 0){
  if(!scrollSpot) return
    
    const { top } = scrollSpot.current.getBoundingClientRect();
    
    const newScrollSpot = window.scrollY + top - scrollMargin;
    window.scrollTo({ 
      top: newScrollSpot, 
      behavior: 'smooth'
    })

  return newScrollSpot
}

const ScrollHandler = ({ children, scrollMargin = 0 }) => {
  //scrollMargin used for additional scrolling adjstments if needed
  
  const lastElementRef = useRef(null);
  const previousChildrenLength = useRef(0);

  useEffect(() => {

    const currentChildrenLength = React.Children.count(children);

    // scroll if the current length is greater than the previous length
    if(currentChildrenLength > previousChildrenLength.current) {
      doScroll(lastElementRef, scrollMargin);
    }

    previousChildrenLength.current = currentChildrenLength;
  }, [children]);

  //last child gets a ref placed inside of it to be used for scrolling
  return (
    <div>
    
      {React.Children.map(children, (child, index) => {

        const isLastChild = index === React.Children.count(children) - 1;
        
        if(!isLastChild)  return child
        
        return (
          <div ref={lastElementRef} >
            {child}
          </div>
        );

      })}
    
    </div>
  );
};

export default ScrollHandler;