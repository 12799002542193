import { createContext, useState, useContext, useEffect } from "react";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";
import { useUserProfile } from "./UserProfileContext";

const ChatHistoryContext = createContext();

function ChatHistoryProvider({ children }) {
	const apiServiceClient = useSymphonyApiService("v2");

    const { userProfile } = useUserProfile();

    // List of chats (based on search filter) and list of all chats.
	const [chats, setChats] = useState([]);
	const [allChats, setAllChats] = useState([]);

    // Statuses
	const [loadingChats, setLoadingChats] = useState(true);

    useEffect(() => {
        if (!userProfile) return;
        loadChats();
    }, [userProfile])

    // Loads the current chats and updates the list.
    const loadChats = async (silent = false) => {
        // Setting loading state for UI loading components
        if(!silent) setLoadingChats(true);
    
        console.log("[CHATHISTORY] Loading chats...");
        let respChats = await apiServiceClient.Chats.getChats(null, null, null, true);
        console.log("[CHATHISTORY] Chats recieved.");
        console.log("[CHATHISTORY] Chats:", respChats);
        //the original position of the chats is recorded for dynamic updates and restoration
        let chatsWithIndex = respChats.chats.map((chat, index) => ( {...chat,originalPosition: index} ));
        // Updating both chats and filtered chats with response
        setChats(chatsWithIndex);
        setAllChats(chatsWithIndex);

        setLoadingChats(false);
    }

    // Filters the current list of chats based on a query.
    const searchChats = async (query, silent = false) => {
        // Handling the event of an empty search query
        if(!query || !query.trim()){
            setChats(allChats);
            return;
        }

        // Setting loading state for UI loading components
        if(!silent) setLoadingChats(true);

        console.log("[CHATHISTORY] Searching chats with query", query);
		let respChats = await apiServiceClient.Chats.searchChats(query);
        // NOTE: chats is the *filtered* list of chats, allChats is all of them.
		setChats([...respChats.chats]);

        setLoadingChats(false);
    }

    // Updates a chat in the list of chats with new data (pin data, name, value, etc.)
    const overrideChatHistory = (chat) => {
        console.log("[CHATHISTORY] Updating chat list with new data...");
        let chatsCopy = [...chats];
        let chatInd = chatsCopy.indexOf((chatItem) => chatItem.chatId === chat.chatId);
        chatsCopy[chatInd] = chat
        setChats([...chatsCopy]);
    }

    // Deletes a chat in the list of chats
    const deleteLocalChat = (chat) => {
        console.log("[CHATHISTORY] Deleting local version of a chat...");
        let chatsCopy = [...chats];
        let chatInd = chatsCopy.indexOf((chatItem) => chatItem.chatId === chat.chatId);
        chatsCopy = [...chatsCopy.slice(0, chatInd), ...chatsCopy.slice(chatInd+1)]
        setChats([...chatsCopy]);
    }

    /*
    *   Functions for updating the list of displayed chats:
    */

    // Update the pin state of a given chatId on the visible list of chats.
    const dynamicPin = (chatId, isPinned) => {
        // Adding originalPosition to chats list allows for dynamic pinning and unpinning chats (unpinning sets back the originalPosition)
        const chatIndex = chats.findIndex(chat => chat.chatId === chatId);
        
        let chatsCopy = [...chats];
        let updatedChat = {
            ...chats[chatIndex],
            isPinned: isPinned
        };
        chatsCopy[chatIndex] = updatedChat;

        if(isPinned){
            updatedChat.pinOrder = Math.max(...chatsCopy.filter(c => c.isPinned).map(c => c.pinOrder), 0) + 1;
        }else{
            delete updatedChat.pinOrder;
        }
        
        chatsCopy.sort((a, b) => {
            if (a.isPinned && b.isPinned) {
              return a.pinOrder - b.pinOrder;
            } else if (a.isPinned) {
              return -1;
            } else if (b.isPinned) {
              return 1;
            } else {
              return a.originalPosition - b.originalPosition;
            }
        });
        setChats(chatsCopy);      
    };

    // Update the name of a given chatId on the visible list of chats.
    const dynamicRename = (chatId, newName) => {
        const updatedChats = chats.map(chat => chat.chatId === chatId ? { ...chat, name: newName } : chat);
        setChats(updatedChats);
    };

    // Updates the visible list of chats when a new chat is created. 
    const dynamicNewChat = (chat) => { 
        // This is a sanity check, because this function is oftentimes called too early due to a race condition.
        // If this conditional does not exist, sometimes when refreshing a chat, the history will only contain that chat.
        // React is fun.
        if (chats.find((listChat) => chat.chatId === listChat.chatId)) {
            // Chat goes after the pinned, and before unpinned
            setChats([...chats.filter((x) => x.isPinned), chat, ...chats.filter((x) => !x.isPinned)]);
        }
    };

    // Updates the visible list of chats when a chat is deleted.
    const dynamicDelete = (chatId) => { 
        setChats(prevChats => prevChats.filter(chat => chat.chatId !== chatId)); 
    }

	return (
		<ChatHistoryContext.Provider
			value={{
				// States
                loadingChats,
                setLoadingChats,
                chats,
                setChats,
                allChats,
                setAllChats,
                // Functions
                loadChats,
                searchChats,
                overrideChatHistory,
                deleteLocalChat,
                dynamicNewChat,
                dynamicRename,
                dynamicDelete,
                dynamicPin,
			}}
		>
			{children}
		</ChatHistoryContext.Provider>
	);
}

// Hook to use the ChatHistoryContext in a component
function useChatHistory() {
	const context = useContext(ChatHistoryContext);
	if (context === undefined) {
		throw new Error(
			"useChatHistory must be used within a ChatHistoryProvider"
		);
	}
	return context;
}

export { ChatHistoryProvider, useChatHistory };
