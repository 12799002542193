import { createContext, useState, useContext, useEffect } from "react";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";

import { useChat } from "./ChatContext";
import { useUserProfile } from "./UserProfileContext";

const SettingsContext = createContext();

function SettingsProvider({ children }) {
	const apiServiceClient = useSymphonyApiService("v2");

    const { chat, chatId, isChatInContext } = useChat();

    const { userProfile, defaultChatSettings, defaultModel, defaultAssistant } = useUserProfile();

    // Current state for model
    const [model, setModel] = useState(null);
    // List of available models
    const [models, setModels] = useState([]);

    // Current state for settings
    // PSEUDONYM: MODEL PARAMETERS
    const [settings, setSettings] = useState([]);

    // Token biz, hot and fresh from the model.
    const [modelEncoding, setModelEncoding] = useState(null);
    const [tokenLimit, setTokenLimit] = useState(0);

    // Flag for being able to make changes to settings in ChatSettingsDrawer.
    const [canEditSettings, setCanEditSettings] = useState(0);

    // Something something token stuff.
    useEffect(() => {
        setModelEncoding(model?.encodingEngine);
        setTokenLimit(model?.tokenLimit ?? 1);
    }, [model])

    // When the chat changes, we need to update the "local" versions of settings and model.
    useEffect(() => {
        console.log("[SETTINGS] Chat object changed. Updating model and settings.")
        // console.log("[SETTINGS] New settings:", chat?.modelParameters);
        // console.log("[SETTINGS] New model:", chat?.model);
        setSettings(chat?.modelParameters ?? []);
        setModel(chat?.model);
    }, [chat])

    const updateSettings = async (index, newValue) => {
        // Deep copy of settings to replace the value, then overwrite.
        // TODO: do we... actually need a deep copy?
        let newSettings = [...settings];
        newSettings[index].value = newValue;
        setSettings(newSettings);
        // API call to update the logged settings.
        console.log("[SETTINGS] Updating settings...");
        if (isChatInContext()) {
            await apiServiceClient.Chats.updateChatModelParameter(chatId, settings[index].modelId, settings[index].modelParameterId, newValue);
            console.log("[SETTINGS] Completed settings update for selected chat.");
        }
        else {
            await apiServiceClient.UserProfiles.updateUserDefaultModelParam(settings[index].modelId, settings[index].modelParameterId, newValue);
            console.log("[SETTINGS] Completed settings update for user.");
        }
    }

    const updateModel = async (newModel) => {
        // Find the model that matches, and overwrite the current model.
        const newModelObj = models.find((model) => model.engine === newModel);
        setModel(newModelObj);
        // API call to update the logged model.
        console.log("[SETTINGS] Updating model...");
        if (isChatInContext()) {
            console.log(newModelObj);
            await apiServiceClient.Chats.updateChatModel(chatId, newModelObj);
            console.log("[SETTINGS] Completed model update for selected chat.");
        }
        else {
            // We can eventually put the call to save a user's default model but because this does literally nothing I have opted to not
            // await apiServiceClient.UserProfiles.saveUserProfileSettings(defaultChatSettings, newModelObj, defaultAssistant)
            console.log("[SETTINGS] Completed model update for user.");
        }
    }

    // Reverts model and settings to default when chat is removed.
    useEffect(() => {
        const revertSettingsData = async () => {
            if (isChatInContext()) return;

            console.log("[SETTINGS] No chat. Reverting model and settings to user defaults...");
            // Based off of that console log, I reckon you know what this code does.
            setSettings([...defaultChatSettings]);
            setModel(defaultModel == null ? models?.find((m) => m.isDefaultTextModel) : defaultModel);
        }

        revertSettingsData();
    }, [chatId])

    // If the user profile changes, we need to update the list of the available models
    useEffect(() => {
        const updateModels = async () => {
            console.log("[SETTINGS] Updating list of models...");

            let models = await apiServiceClient.Models.getModels();
            console.log("[SETTINGS] Models found:", models);
            setModels([...models.filter((x) => x.isEnabled && x.isTextModel)]);
        }

        updateModels();
    }, [userProfile])

    // This sets the chat settings to initialSettings if not already set to something meaningful
    useEffect(() => {
        if (!settings || ((settings.length === 0) && (defaultChatSettings.length !== 0))) {
            console.log("[SETTINGS] No current settings. Using default settings...")

            setSettings([...defaultChatSettings]);
        }
    }, [defaultChatSettings, settings]);

    // This sets the model to something if not already set to something meaningful
    useEffect(() => {
        if (!model) {
            console.log("[SETTINGS] No current model. Using a default model...")

            let selectedModel = defaultModel ?? models?.find((m) => m.isDefaultTextModel) ?? models?.[0] ?? null;
            setModel(selectedModel);
        }
    }, [defaultModel, model, models]);

	return (
		<SettingsContext.Provider
			value={{
                // States
				model,
                setModel,
                models,
                settings,
                setSettings,
                modelEncoding,
                tokenLimit,
                canEditSettings,
                // Functions
                updateSettings,
                updateModel,
			}}
		>
			{children}
		</SettingsContext.Provider>
	);
}

// Hook to use the SettingsContext in a component
function useSettings() {
	const context = useContext(SettingsContext);
	if (context === undefined) {
		throw new Error(
			"useSettings must be used within a SettingsProvider"
		);
	}
	return context;
}

export { SettingsProvider, useSettings };
