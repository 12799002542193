import { useState, useRef } from "react";
import { Button, Heading } from "cai-fusion";
import FileDisplay from "../components/symphonyFileDisplay";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";

import { useTemplate } from "../contexts/TemplateContext";

const CodeReview = () => {

	const codeReviewPrompt = `I am working on a project and would like a code review for the attached files.\r\nPlease review the code for the following aspects:\r\n1. **Correctness**: Are there any bugs or logical errors?\r\n2. **Efficiency**: Are there any performance improvements that can be made?\r\n3. **Readability**: Is the code well-structured and easy to understand?\r\n4. **Best Practices**: Does the code follow industry best practices and conventions?\r\n\r\nAny additional suggestions or improvements are also welcome.\r\n\r\nIf there is not enough context to do a thorough review, please ask specifically for more information before performing the review.`;

	const { isRunning, executeTemplate } = useTemplate()

	const [files, setFiles] = useState([]);

	const deleteFiles = (fileInd) => {
		let filesCopy = [...files];
		filesCopy = [...filesCopy.slice(0, fileInd), ...filesCopy.slice(fileInd + 1)]
		setFiles([...filesCopy]);
	}

	const handleUploads = async () => {
		executeTemplate(files, [codeReviewPrompt]);
	}

	const handleFileUpload = async (newFiles) => {
		setFiles([...files, ...newFiles]);
		console.log("[TEMPLATE] Added files. New file list:", ([...files], [...newFiles]));
	}

	return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To begin getting help reviewing your code, just upload your code
				and related files in the file drop and hit that Send Message button!
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				<FileDisplay filesOverride={files} deleteOverride={deleteFiles} />
				<div style={{ marginBottom: "20px" }}>
					<DnDFileUpload
						notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1',
							'.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx',
							'.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
							'.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
						overwriteHandleFiles={handleFileUpload}
					/>
				</div>
				<div className="m-button-row">
					<Button onClick={handleUploads} disabled={files.length === 0 || isRunning}>
						Send Message
					</Button>
				</div>
			</form>
		</>
	);
};

export default CodeReview;