import ContentRenders from "./ContentRenders";

const ChatEntryMsg = ({ content }) => {
  return (
    <>
      {content
        .sort((a, b) => a.contentOrder - b.contentOrder)
        .map((contentItem, index) => {
          if (contentItem.type === "text") {
            return (
              <ContentRenders
                key={index}
                content={contentItem.text}
              />
            );
          } else if (contentItem.type === "image_url") {
            return (
              <div className="m-chat-bubble__image-wrapper" key={index}>
                <img
                  className="m-chat-bubble__image"
                  src={`${contentItem.imageUrl}`}
                  alt={contentItem.imageTextDescription}
                />
              </div>
            );
          } else if (contentItem.type === "image_bytes") {
            return (
              <div className="m-chat-bubble__image-wrapper" key={index}>
                <img
                  className="m-chat-bubble__image"
                  src={`data:image/jpeg;base64,${contentItem.imageBytes}`}
                  alt={contentItem.imageTextDescription}
                />
              </div>
            );
          } else if (contentItem.type === "html") {
            return (
              <div
                dangerouslySetInnerHTML={{ __html: contentItem.html }}
                key={index}
              />
            );
          } else {
            return <></>;
          }
        })}
    </>
  );
};

export default ChatEntryMsg;