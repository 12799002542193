import Markdown from 'react-markdown';
import katex from 'katex';
import ReactJson from '@microlink/react-json-view';
import mermaid from 'mermaid';
import { memo, useState, useEffect } from 'react';
import { getCodeString } from 'rehype-rewrite';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ReactComponent as IconCopy } from '../../../../images/icon-copy.svg';

//Combination of Link, Code and Mermaid for Rendering Symphony Text    

const ContentRenders = memo(({ content }) => {
    return (
        <Markdown
            children={content}
            components={{
                code: MdCodeRenderer,
                a: MdLinkRenderer,
            }}
        />
    );
});

export default ContentRenders;

const renderKaTeX = (code) => {
    const html = katex.renderToString(code, { throwOnError: false });
    return <code style={{ fontSize: "150%" }} dangerouslySetInnerHTML={{ __html: html }} />;
};

const renderMermaid = (code) => <MermaidComponent chart={code} />;

const renderJSON = (code) => {
    try {
        const parsedJson = JSON.parse(code);
        return <ReactJson src={parsedJson} />;
    } catch (error) {
        console.error("Failed to parse JSON:", error);
        return <code>Invalid JSON</code>;
    }
};

const MdCodeRenderer = ({ inline, children = [], className, ...props }) => {
    const [copied, setCopied] = useState(false);
    const txt = children[0] || "";
    const code = props.node && props.node.children ? getCodeString(props.node.children) : txt;

    const isKaTeX = /^language-katex/.test(className?.toLowerCase());
    const isMermaid = /^language-mermaid/.test(className?.toLowerCase());
    const isJSON = /^language-json/.test(className?.toLowerCase());

    if (inline) {
        if (/^\$\$(.*)\$\$/.test(txt)) {
            const html = katex.renderToString(txt.replace(/^\$\$(.*)\$\$/, "$1"), { throwOnError: false });
            return <code dangerouslySetInnerHTML={{ __html: html }} />;
        }
        return <code>{txt}</code>;
    }

    let codeBlock;
    if (isMermaid) {
        codeBlock = renderMermaid(code);
    } else if (isKaTeX) {
        codeBlock = renderKaTeX(code);
    } else if (isJSON) {
        codeBlock = renderJSON(code);
    } else {
        codeBlock = (
            <SyntaxHighlighter language={className?.replace("language-", "")} customStyle={{ borderRadius: "8px" }} style={vs}>
                {code}
            </SyntaxHighlighter>
        );
    }

	const handleCopyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(code);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        } catch (err) {
            console.error('Failed to copy text to clipboard:', err);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <button 
                onClick={handleCopyToClipboard} 
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    padding: '0',
                    cursor: 'pointer'
                }}
            >
                <IconCopy width="17" height="17" />
            </button>

            {codeBlock}
        </div>
    );
};

const MdLinkRenderer = ({ ...props }) => (
    <a {...props} target="_blank" rel="noopener noreferrer">
        {props.children}
    </a>
);

mermaid.initialize({ startOnLoad: true });

const MermaidComponent = ({ chart }) => {
  useEffect(() => {
    mermaid.contentLoaded();
  }, [chart]);
  return (
    <div id="mermaid-chart" className="mermaid">
      {chart}
    </div>
  );
};

// const handleDownload = () => {
//     const blob = new Blob([code], { type: "text/plain; charset=utf-8" });
//     const fileType = className?.split("-")[1] || "txt"; // infer the file type from className
//     const link = document.createElement("a");
//     link.href = URL.createObjectURL(blob);
//     link.download = `code.${fileType}`;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
// };
