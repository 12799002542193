import { useEffect, useRef } from "react";
import ChatBubble from "./ChatBubble";
import ChatSuggestions from "./ChatSuggestions";
import { ReactComponent as IconAttach } from "../../../images/icon-attach.svg";
import LoadingTips from "../../symphony/components/loadingTips";
import { useMessages } from "../../symphony/contexts/MessageContext";
import { useUserProfile } from "../../symphony/contexts/UserProfileContext";
import { useAccess } from "../../symphony/contexts/AccessContext";
import { useChat } from "../../symphony/contexts/ChatContext";
import LoadingChat from "./LoadingChat";
import ScrollHandler from "../../symphony/components/scrollHandler";

const Chat = ({
  chatEntryActions = {},
  iconElements,
  editingMessageId,
  setEditingMessageId,
}) => {
  const messagesStartRef = useRef(null);
  const messagesEndRef = useRef(null);
  const { canSendMessage, chatMembers } = useAccess();
  const { loadingChat } = useChat();
  const { messages, editMessage, sendMessage, chatSuggestions, assistantThinking } = useMessages();
  const { userProfile, compileNameHelper } = useUserProfile();

  const userMessageRef = useRef(null);

  const handleTipAction = (action, tip) => {
    console.log(`User clicked ${action} on tip: `, tip);
  };

  const renderImageMessage = (message) => (
    <ChatBubble
      key={message.messageId}
      messageId={message.messageId}
      messagePairId={message.messagePairId}
      role={message.role}
      content={message.content.slice(1)}
      statusMessage={message.statusMessage}
      IconElement={iconElements ? iconElements["user"] : null}
      isLoading={message.isLoading}
      chatEntryActions={chatEntryActions[message.role] || []}
      userProfileId={message.userProfileId}
      isEditing={message.messageId === editingMessageId}
      setEditingMessageId={setEditingMessageId}
      resendMessage={editMessage}
      timestamp={message.messageDate}
      displayName={compileNameHelper(chatMembers.find(x => x.userProfileId === message.userProfileId)?.userProfile) ?? "Another User"}
    />
  );

  const renderEventMessage = (message, messageText) => {
    let eventMessage = messageText;

    if (message.role.includes("datasource")) {
      eventMessage = (
        <>
          <IconAttach className="m-chat-event__icon" />
          <strong>{messageText}</strong>{" "}
          {messageText.split(",").length > 1 ? "were" : "was"}{" "}
          {message.role.startsWith("info") ? "included in" : "removed from"} the chat{" "}
          {chatMembers.length > 1 ? `by ${compileNameHelper(chatMembers.find(x => x.userProfileId === message.userProfileId)?.userProfile) ?? "another member."}` : ""}.
        </>
      );
    }

    return (
      <div
        className={`m-chat-event${message.role.startsWith("warn") ? " m-chat-event--removal" : ""}`}
        key={message.messageId}
      >
        <hr className="m-chat-event__line" />
        <p className="m-chat-event__label">{eventMessage}</p>
      </div>
    );
  };

  //The catch all rendering of a chat message
  const renderChatEntry = (message) => (
    <ChatBubble
      key={message.messageId}
      messageId={message.messageId}
      messagePairId={message.messagePairId}
      role={message.role}
      content={message.content}
      statusMessage={message.statusMessage}
      IconElement={iconElements ? iconElements[message.role] : null}
      isLoading={message.isLoading}
      chatEntryActions={chatEntryActions[message.role] || []}
      userProfileId={message.userProfileId}
      isEditing={message.messageId === editingMessageId}
      setEditingMessageId={setEditingMessageId}
      resendMessage={editMessage}
      timestamp={message.messageDate}
      displayName="Symphony Assistant"
    />
  );

  const renderUserMessage = (message) => (
    <div key={message.messageId}>
      <ChatBubble
        messageId={message.messageId}
        messagePairId={message.messagePairId}
        role={message.role}
        content={message.content}
        statusMessage={message.statusMessage}
        IconElement={iconElements ? iconElements[message.role] : null}
        isLoading={message.isLoading}
        chatEntryActions={chatEntryActions[message.role] || []}
        userProfileId={message.userProfileId}
        isEditing={message.messageId === editingMessageId}
        setEditingMessageId={setEditingMessageId}
        resendMessage={editMessage}
        timestamp={message.messageDate}
        displayName={compileNameHelper(chatMembers.find(x => x.userProfileId === message.userProfileId)?.userProfile) ?? "Another User"}
      />
    </div>
  );

  const renderChatMessage = (message, index) => {
    
    const messageText = getMessageText(message);

    switch (message?.role) {
      case "user":
        return renderUserMessage(message);
      case "info-datasource-image":
        return renderImageMessage(message);
      default:
        if (message?.role?.startsWith("info") || message?.role?.startsWith("warn")) {
          return renderEventMessage(message, messageText);
        }
        return renderChatEntry(message);
    }
  };

  //Could be done in messageContext (i.e. when setting messages it is already sorted), no logic needed for visual components
  const sortedMessages = [...messages].sort((a, b) => a.messageDate - b.messageDate);

  const getMessageText = (message) => {
    return message.content
      .filter((contentItem) => contentItem.type === "text")
      .sort((a, b) => a.contentOrder - b.contentOrder)
      .map((contentItem) => contentItem.text)
      .join("\n");
  };
  
  return (
    <div className="o-chat" style={{ paddingBottom: chatSuggestions?.length > 0 ? "0px" : "256px" }}>
      <div ref={messagesStartRef} />
      
      {loadingChat ? 
        ( <LoadingChat iconElements={iconElements} userProfileId={userProfile.userProfileId} /> ) 
        : 
        (
          <ScrollHandler scrollMargin={80}>
            {sortedMessages.map((message, index) => renderChatMessage(message, index))}
          </ScrollHandler>
        )
      }
      
      <LoadingTips isLoading={assistantThinking} onTipAction={handleTipAction} />
      
      {canSendMessage && chatSuggestions?.length > 0 && (
       
       <ChatSuggestions
          suggestions={chatSuggestions}
          onSuggestionSelected={sendMessage}
        />
      
      )}

      <div ref={messagesEndRef} />
    </div>
  );
};

export default Chat;
