import { createContext, useState, useContext, useEffect, useRef } from "react";
import { ToastContext } from "cai-fusion";
import { useUserProfile } from "./UserProfileContext";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";
import { useChat } from "./ChatContext";
import { useSignalR } from "./SignalRContext";
import { useSettings } from "./SettingsContext";
import { createUserFeedback } from "../utilities/UploadFeedback";
import { useDataStore } from "./DataStoreContext";

const ResourceContext = createContext();
function ResourceProvider({ children }) {

    const apiServiceClient = useSymphonyApiService("v2");

    const { userProfile } = useUserProfile();
    const { modelEncoding } = useSettings();

    const { chat, chatId, fetchChatId, isChatInContext } = useChat();

    const [files, setFiles] = useState([]);
    const [backUpFiles, setBackUpFiles] = useState([]); //file backup in case of error

    const { dataStores, allDataStores, searchDataStores } = useDataStore();
    const [enabledDataStores, setEnabledDataStores] = useState([]);

    const [dataSources, setDataSources] = useState([]);

    const { addMappingMethod } = useSignalR();
    const [resourceTokenCount, setResourceTokenCount] = useState(0);

    const [uploadComplete, setUploadComplete] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const { createToast } = useContext(ToastContext);

    //variable to share the status of uploading to other components
    const statusShare = (status) => {
        setUploadComplete(status !== 'error');
        if (status === 'error') setFiles([...backUpFiles]);
    };

    // TBD
    // useEffect(() => {
    //     setEnabledDataStores(chat.dataStores)
    // }, [chat])

    useEffect(() => {
        const getDataSources = async () => {
            console.log("[GETDATASOURCES] Getting data sources...");
            let respDataSources = await apiServiceClient.DataSources.getExternalDataSources();
            setDataSources(respDataSources ?? []);
        }
        getDataSources();
    }, [userProfile]);

    useEffect(() => {
        console.log(`Resource Token Count Upladed to: ${resourceTokenCount}`);
    }, [resourceTokenCount]);

    //For new chat and select a different chat
    useEffect(() => {
        if (!isUploading) {
            if (!chatId) {
                setFiles([]);
                setResourceTokenCount(0);
                setEnabledDataStores([...allDataStores.filter(x => x.isFavorited)]);
                //setDataSources();
            } else {
                getChatResources(chatId);
                setEnabledDataStores(chat?.dataStores?.filter(dsi => dsi.isEnabled)?.map(dsa => dsa.dataStore) ?? []);
            }
        }
    }, [chatId, dataStores, chat]);//, dataStores]);

    const handleFilesUpload = async (addedFiles) => {
        if (!Array.isArray(addedFiles)) addedFiles = Array.from(addedFiles); //Override files return as non Array types, Array.from fixes this

        setBackUpFiles([...files]);

        addedFiles = filterDuplicates(files, addedFiles);
        if (!addedFiles.length) return;

        console.log("Welcome to ResourceContext");

        setIsUploading(true);
        setFiles(prevFiles => [...prevFiles, ...addedFiles]);

        const sessionChatId = await fetchChatId(); //Make sessionChatId go up first

        await Promise.all(addedFiles.map(file => uploadChatResource(sessionChatId, file)));
        await getChatResources(sessionChatId);

        chatResourceUserFeedback(addedFiles);
        setIsUploading(false);
    };

    const filterDuplicates = (currentFiles, addedFiles) => {
        const nonDuplicateFiles = addedFiles.filter(addedFile =>
            !currentFiles.some(file => file.resourceName === addedFile.resourceName)
        );
        return nonDuplicateFiles;
    };

    // Does the API call to upload a new resource.
    const uploadChatResource = async (sessionChatId, file) => {
        try {
            const response = await apiServiceClient.Chats.addChatResource(sessionChatId, file);
            file.isSuccess = response?.isSuccess ?? false;
            file.Message = response?.message || (file.isSuccess ? "" : "An unknown error");
            if (file.isSuccess) updateUploadingStatus(file);
            await activeUploadTokenCount(response.result.resourceEncodings);
        } catch (error) {
            //a catch all error, controlled errors get handled in if condition above
            console.error(`[UPLOAD]Error uploading file: ${file}`, error);
            file.isSuccess = false;
            file.Message = "Internal Problem. Try uploading Resource Again."
        }
        return file;
    };

    //File state changes during upload session
    const updateUploadingStatus = (file) => {
        setFiles(prevFiles => {
            const fileIndex = prevFiles.findIndex(fileMain => fileMain.resourceName === file.resourceName);
            if (fileIndex === -1) return prevFiles;

            const updatedFiles = [...prevFiles];
            updatedFiles[fileIndex] = { ...updatedFiles[fileIndex], uploading: false };
            return updatedFiles;
        });
    };

    //active memory adjustments during uploading sessions 
    const activeUploadTokenCount = async (items) => {
        const sum = items.reduce((acc, item) => {
            if (item.encodingEngine === modelEncoding) return acc + item.tokenCount;
            return acc;
        }, 0);
        setResourceTokenCount(prevCount => prevCount + sum);
    };

    // When a data source is included/no longer included
    const handleDataSourcesChange = (index) => {
        const newDataSource = [...dataSources];
        newDataSource[index].included = !newDataSource[index].included;
        setDataSources(newDataSource);
    };

    const calculateResourceTokenCount = (resources) => {
        if (!resources || resources.length === 0) {
            setResourceTokenCount(0);
            return;
        }
    
        const total = resources.reduce((acc, file) => {
            const itemSum = file.resourceEncodings.reduce((tokenAcc, item) => {
                // Check if the condition is true and return the new token count
                if(item.encodingEngine === modelEncoding) return tokenAcc + item.tokenCount;
                // If the condition is not met, return the tokenAcc as it is
                return tokenAcc;
            }, 0);
            return acc + itemSum;
        }, 0);
    
        setResourceTokenCount(total);
    };

    const getChatResources = async (chatId) => {
        if (!chatId) return;
        let resources;
        try {
            resources = await apiServiceClient.Chats.getChatResources(chatId);
            if (resources) {
                // // Filter out the resources that are already in the files array
                // const newResources = resources.filter(resource => 
                //     !files.some(file => file.resourceName === resource.resourceName)
                // );

                setFiles([...resources]);
                calculateResourceTokenCount(resources);
            }
        } catch (error) {
            console.log("[GETRESOURCE] Error " + error);
            setFiles([...backUpFiles]);
            calculateResourceTokenCount(backUpFiles);
        }
    }

    // Delete existing chat resource
    const deleteChatResource = async (fileToDelete) => {
        if (fileToDelete.id === undefined) return;
        try {
            const newFiles = files.filter(file => file.id !== fileToDelete.id);
            setFiles(newFiles);
            await apiServiceClient.Chats.deleteChatResource(chatId, fileToDelete.id);
            calculateResourceTokenCount(newFiles);
        } catch (error) {
            console.log("[DELRESOURCE] Error: " + error + " with ChatId: " + chatId + " and file.id: " + fileToDelete);
        }
    }

    const chatResourceUserFeedback = (addedFiles) => createUserFeedback(addedFiles, statusShare, createToast);

    // signalR response to new resource being added
    const receiveNewResource = async (resources) => {
        let chatId = await fetchChatId();
        if (resources[0]?.chatId === chatId) {
            // Add resource to current chat
            try {
                const fileIndex = filesRef.current.findIndex(x => x.resourceName === resources[0].resourceName);
                if (fileIndex === -1) {
                    setFiles(...filesRef.current, resources[0]);
                    return;
                }
                setFiles([...filesRef.current.slice(0, fileIndex), resources[0], ...filesRef.current.slice(fileIndex)])
                //await loadMessages(chatId);
                // TODO: possibly need addResourceToMessages func here but not sure yet
            }
            catch (error) {
                console.error("[RECRESOURCE] Error getting chat");
            }
        }
        else {
            // This is a place for code to be executed if the message was sent in a chat that is not the current chat. 
        }
    }

    const filesRef = useRef();

    useEffect(() => {
        filesRef.current = files
    }, [files])

    useEffect(() => {
        addMappingMethod(
            {
                //This method will be called when a new resource is received from the user. This resource with have an empty GUID since it is not yet finalized in the database.
                TextName: "ReceiveNewResource",
                Method: receiveNewResource,
            }
        );
    }, [])

    const enableDataStore = async (dataStore) => {
        await apiServiceClient.Chats.EnableDatastore(await fetchChatId(), dataStore.id);
        setEnabledDataStores([...enabledDataStores, dataStore])
    }

    const disableDataStore = async (dataStore) => {
        await apiServiceClient.Chats.DisableDataStore(await fetchChatId(), dataStore.id);
        setEnabledDataStores(enabledDataStores.filter(x => x.id !== dataStore.id))
    }

    return (
        <ResourceContext.Provider
            value={{
                // States
                uploadComplete,
                resourceTokenCount,
                dataSources,
                files,
                enabledDataStores,
                setEnabledDataStores,
                setUploadComplete,
                setDataSources,
                setFiles,
                // Functions
                handleDataSourcesChange,
                enableDataStore,
                disableDataStore,
                handleFilesUpload,
                uploadChatResource,
                deleteChatResource,
            }}
        >
            {children}
        </ResourceContext.Provider>
    );
}

function useResource() {
    const context = useContext(ResourceContext);
    if (!context) throw new Error("useResource must be used within a ResourceProvider");
    return context;
}

export { ResourceProvider, useResource };