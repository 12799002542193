const ChatEntryEdit = ({
    editedMessage,
    setEditedMessage,
    handleResubmit,
    setEditingMessageId
  }) => {
    return (
      <>
        <div className="m-form-group">
          <label htmlFor="" className="a-label">
            Edited Message
          </label>
          <textarea
            value={editedMessage}
            rows={5}
            onChange={(e) => setEditedMessage(e.target.value)}
            className="a-textarea-input"
            style={{ width: "100%" }}
          />
        </div>
        <div className="m-button-row">
          <button
            className="a-button a-button--link m-button-row__button"
            onClick={(e) => {
              e.preventDefault();
              setEditingMessageId(null);
            }}
          >
            Cancel
          </button>
          <button
            className="a-button m-button-row__button"
            onClick={(e) => {
              e.preventDefault();
              handleResubmit();
            }}
          >
            Send
          </button>
        </div>
      </>
    );
  };
  export default ChatEntryEdit;