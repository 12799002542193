import { Heading, Drawer, Button, ContextMenu, ContextMenuItem, HeadingRow, Pagination } from "cai-fusion";
import React, { useEffect, useState } from "react"

import NewDataStoreModal from "../overlay/newDataStoreModal";

// Side menu icons
import { ReactComponent as IconPerson } from "../../../images/icon-person.svg";
import { ReactComponent as IconUsers } from "../../../images/icon-users.svg";
import { ReactComponent as IconBuilding } from "../../../images/icon-building.svg";
// Data store icons
import { ReactComponent as IconFavorite } from "../../../images/icon-favorite.svg";
import { ReactComponent as IconMagGlass } from "../../../images/icon-mag-glass.svg";
// Context menu icons
import { ReactComponent as IconTrash } from "../../../images/icon-trash.svg";
import { ReactComponent as IconShare } from "../../../images/icon-share.svg";
import { ReactComponent as IconRename } from "../../../images/icon-rename.svg";
// Search bar search icon
import ImageSearch from "../../../images/search-icon-light.png"
// Loading bar
import { ReactComponent as RippleLoading } from "../../../images/ripple-loading.svg";

import { useDataStore } from "../contexts/DataStoreContext";
import { useUserProfile } from "../contexts/UserProfileContext";
import DeleteDataStoreModal from "../overlay/deleteDataStoreModal";
import ShareDataStoreDrawer from "../overlay/shareDataStore";

const MenuOption = ({ id, name, isActive, onClick, SVGObject }) => {

    return (
        <li className={`m-vertical-tabs__item ${isActive ? "m-vertical-tabs__item--active" : ""}`}
            id={`tab${id}`}
            role="tab"
            tabIndex={id}
            aria-controls={`tabpanel${id}`}
            aria-selected="true"
        >
            <a className="m-vertical-tabs__link" onClick={onClick}>
                <div className="m-vertical-tabs__icon-wrapper">
                    <SVGObject className="m-vertical-tabs__icon"/>
                </div>
                <span className="m-vertical-tabs__label">{name}</span>
            </a>
        </li>
    )
}

const DataStore = ({dataStoreObj, setHideDeleteModal, setHideShareDrawer, setHideNameModal, setIsRenaming}) => {
    const [isHidden, setIsHidden] = useState(true);

    const { selectDataStore, setDataStoreId, toggleFavoriteDataStore } = useDataStore(); 

    return (
        <tr className="o-treegrid__row o-treegrid__row--shown" key={dataStoreObj.id}>
            {/* Name & Description */}
            <td className="o-treegrid__cell">
                <p className="a-subtle-text l-mb-none">
                    <strong>
                        <a className="a-link" href="#/" onClick={() => {if(dataStoreObj.id !== "loadingitem") selectDataStore(dataStoreObj.id)}}>
                            {dataStoreObj.name}
                        </a>
                    </strong>
                </p>
                <p className="a-subtle-text a-muted-text l-mt-none">
                    {dataStoreObj.description}
                </p>
            </td>
            {/* Shared Icon */}
            <td className="o-treegrid__cell o-treegrid__cell--right-aligned">
                { dataStoreObj.isShared && 
                <button 
                    className="a-tag a-tag--interactive"
                    onClick={() => {setDataStoreId(dataStoreObj.id); setHideShareDrawer(false);}}
                >
                    <IconUsers className="a-tag__icon" />
                    <p className="a-tag__label">Shared</p>
                </button> }
            </td>
            {/* Buttons */}
            <td className="o-treegrid__cell">
                { dataStoreObj.id === "loadingitem" ?
                    <>
                        <RippleLoading className="a-icon__img" style={{maxHeight: 50}}/>
                    </> :
                    <div className="m-button-row">
                        <ContextMenu
                            isHidden={isHidden}
                            onClick={() => setIsHidden(!isHidden)}
                            onBlur={() => setIsHidden(true)}
                        >
                            <ContextMenuItem 
                                Icon={IconRename} 
                                name="Rename Collection" 
                                onClick={() => {setDataStoreId(dataStoreObj.id); setIsRenaming(true); setHideNameModal(false);}}  
                            />
                            <ContextMenuItem 
                                Icon={IconShare} 
                                name="Share Collection" 
                                onClick={() => {setDataStoreId(dataStoreObj.id); setHideShareDrawer(false);}}
                            />
                            <ContextMenuItem 
                                Icon={IconTrash} 
                                name="Delete Collection" 
                                danger 
                                onClick={() => {setDataStoreId(dataStoreObj.id); setHideDeleteModal(false);}}
                            />
                        </ContextMenu>
                        <button 
                            className={`a-icon-link${dataStoreObj?.isFavorited ? " a-icon-link--active" : ""}`}
                            onClick={() => toggleFavoriteDataStore(dataStoreObj.id, dataStoreObj.isFavorited)}
                        >
                            <div className="a-icon-link__inner">
                                <div className="a-icon-link__icon-wrapper">
                                    <IconFavorite className="a-icon-link__icon" />
                                </div>
                            </div>
                        </button>
                        <button 
                            className="a-icon-link"
                            onClick={() => selectDataStore(dataStoreObj.id)}
                        >
                            <div className="a-icon-link__inner">
                                <div className="a-icon-link__icon-wrapper">
                                    <IconMagGlass className="a-icon-link__icon" />
                                </div>
                            </div>
                        </button>
                    </div> 
                }
            </td>
        </tr>
    )
}

const SymphonyDataStores = () => {

    const { dataStores, setDataStoreId, setDataStore, searchDataStores, isLoading } = useDataStore();
    const { userProfile } = useUserProfile();
    // The currently selected tab, used to filter the data stores by shared/public/etc. state.
    const [tabID, setTabID] = useState(1);
    // States used to filter the data stores further.
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");

    const [hideNameModal, setHideNameModal] = useState(true);
    const [isRenaming, setIsRenaming] = useState(false);
    const [hideShareDrawer, setHideShareDrawer] = useState(true);
    const [hideDeleteModal, setHideDeleteModal] = useState(true);

    const [filteredDataStores, setFilteredDataStores] = useState([]);

    const handleIconPress = (event) => {
        event.preventDefault();
        searchDataStores(searchText);
    }

    // Logic to reset values and set the document title accordingly.
    useEffect(() => {
        setDataStoreId(undefined);
        setDataStore({});
        document.title = "Symphony - Data Collections"
    }, [])

    // Logic to handle the shown data stores based on the filter specified by the drawer on the left side of the screen.
    useEffect(() => {
        if (tabID === 1) {
            setFilteredDataStores([...dataStores.filter((dataStore) => !dataStore.isPublished)]);
        }
        else if (tabID === 2) {
            setFilteredDataStores([...dataStores.filter((dataStore) => dataStore.userProfileId !== userProfile.userProfileId)]);
        }
        else {
            setFilteredDataStores([...dataStores.filter((dataStore) => dataStore.isPublished)]);
        }
    }, [dataStores, tabID])

    useEffect(() => {
        // A check to update pagination as needed - if we're on a page that no longer exists, go back one.
        if (page > 1 && dataStores.length <= (page - 1) * 10) {
            setPage(page - 1);
        }
    }, [dataStores])

    return (
        <>
            <div className="o-page-section l-mt-md l-pt-none">
                <div className="o-page-section__content-container o-page-section__content-container--med-wide-fixed-width l-pt-none l-mt-neg-sm">
                    <div className="l-row l-pt-sm">
                        <Drawer 
                            className="o-drawer--left-narrow col-md-3"
                            type="hybrid"
                            header={
                                <div className="o-drawer-heading-row">
                                    <div className="o-drawer-title">
                                        <Heading size={3}>Data Collections</Heading>
                                    </div>
                                </div>
                            }
                            children={
                                <nav className="m-vertical-tabs">
                                    <ul className="m-vertical-tabs__list" role="tablist" aria-orientation="vertical">
                                        <MenuOption id={1} name="My Collections" isActive={tabID === 1} onClick={() => setTabID(1)} SVGObject={IconPerson}/>
                                        <MenuOption id={2} name="Shared With Me" isActive={tabID === 2} onClick={() => setTabID(2)} SVGObject={IconUsers}/>
                                        <MenuOption id={3} name="Public Collections" isActive={tabID === 3} onClick={() => setTabID(3)} SVGObject={IconBuilding}/>   
                                    </ul>
                                </nav>
                            }
                        />
                        <div className="col-md-9">
                            {/* TODO: Conditionally render based off of tabID */}
                            <HeadingRow
                                title={"My Data Collections"}
                            >
                                <Button
                                    children="Create New Collection"
                                    onClick={() => {
                                        setTabID(1);
                                        setHideNameModal(false);
                                        setIsRenaming(false);
                                    }}
                                />
                            </HeadingRow>
                            <p className="a-muted-text">
                                {"Data collections let you gather related files and website in one place so that you can quickly include them in chats with Symphony. " + 
                                    (
                                        tabID === 1 ?  "There are also a number of helpful CAI data collections you can include in any chat." :
                                        tabID === 2 ?  "These are the collections that were shared with you." :
                                        tabID === 3 && "You can create your own, or have them be shared with you."   
                                    )
                                }
                            </p>
                            <div className="row">
                                <div className="col-md-12">
                                    <form className="a-search-input l-mb-xs l-mt-xs">
                                        <input 
                                            className="a-search-input__text-box"
                                            type="text"
                                            placeholder="Search collections..."
                                            id="search-field"
                                            name="search-field"
                                            value={searchText || ''}
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                        <button 
                                            className="a-search-input__button"
                                            type="submit"
                                            onClick={handleIconPress}
                                        >
                                            <img className="a-search-input__img" src={ImageSearch} />
                                        </button>
                                    </form>
                                    <div className="o-block l-mt-xs">
                                        { (isLoading && dataStores.length === 0) ?
                                            <div className="text-center" aria-colspan={4}>
                                                <RippleLoading className="a-icon__img" style={{maxHeight: 50}}/>
                                                <p className="a-muted-text">Loading available data collections...</p>   
                                            </div> :
                                            <>
                                                { (filteredDataStores.length === 0) ? 
                                                    <div className="text-center" aria-colspan={4}>
                                                        <p className="a-muted-text">
                                                            {
                                                                tabID === 1 ?  "You have no data collections that fit this criteria. Create one using the button above!" :
                                                                tabID === 2 ?  "There are no data collections shared with you that fit this criteria." :
                                                                tabID === 3 && "There are currently no public data collections that fit this criteria."
                                                            }
                                                        </p>   
                                                    </div> :
                                                    <>
                                                        <table id="dataStores" className="o-treegrid o-treegrid--compact l-mr-sm">
                                                            <colgroup className="o-treegrid__colgroup">
                                                                <col span="1" className="o-treegrid__col o-treegrid__col--xxx-wide" /> { /* Name & Desc */ }
                                                                <col span="1" className="o-treegrid__col o-treegrid__col--small" /> { /* Shared Button */ }
                                                                <col span="1" className="o-treegrid__col o-treegrid__col--medium" /> { /* Action Buttons */ }
                                                            </colgroup>
                                                            <tbody>
                                                                {/* Take a slice based on the current page. If we're at the end of the list, we don't want to access indexes out of bounds. */}
                                                                {filteredDataStores.slice((page - 1) * 10, Math.min(filteredDataStores.length, page * 10)).map((dataStore) => {
                                                                    return (
                                                                        <DataStore 
                                                                            dataStoreObj={dataStore}
                                                                            key={dataStore.id}
                                                                            setHideDeleteModal={setHideDeleteModal}
                                                                            setHideShareDrawer={setHideShareDrawer}
                                                                            setHideNameModal={setHideNameModal}
                                                                            setIsRenaming={setIsRenaming}
                                                                        />
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        <Pagination 
                                                            numPerPage={10}
                                                            data={dataStores}
                                                            currentPage={page}
                                                            onPageChange={(page) => setPage(page)}
                                                        />
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NewDataStoreModal
                isHidden={hideNameModal}
                onClose={() => {
                    setHideNameModal(true);
                    setDataStoreId(undefined);
                }}
                clickOutsideToClose
                isRenaming={isRenaming}
            />
            <DeleteDataStoreModal 
                isHidden={hideDeleteModal}
                onClose={() => {
                    setHideDeleteModal(true);
                    setDataStoreId(undefined);
                }}
                clickOutsideToClose
            />
            <ShareDataStoreDrawer 
                isHidden={hideShareDrawer}
                onClose={() => {
                    setHideShareDrawer(true);
                    setDataStoreId(undefined);
                }}
                clickOutsideToClose
            />
        </>
    )
}

export default SymphonyDataStores;