import { createContext, useState, useContext, useEffect } from "react";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";
import { useUserProfile } from "./UserProfileContext";

const ComplianceContext = createContext();

function ComplianceProvider({ children }) {
    const apiServiceClient = useSymphonyApiService();

	const { userProfile } = useUserProfile();

	const [outstandingEULA, setOutstandingEULA] = useState(null);
	const [agreedToEULA, setAgreedToEULA] = useState(true);


	useEffect(() => {
		apiServiceClient.UserProfiles.getPendingEULA().then((EULA) => {
			if (EULA) {
				setOutstandingEULA(EULA);
			} else {
				setAgreedToEULA(true);
			}
		});
	}, [userProfile]);

	const agreeToEULA = () => {
		apiServiceClient.UserProfiles.agreeToEULA(outstandingEULA.id).then((resp) => {
			if (resp === "false") {
				setAgreedToEULA(false);
			} else {
				setAgreedToEULA(true);
				setOutstandingEULA(null);
			}
		});
	};

	return (
		<ComplianceContext.Provider
			value={{
				agreedToEULA,
				agreeToEULA,
				outstandingEULA,
				setAgreedToEULA,
			}}
		>
			{children}
		</ComplianceContext.Provider>
	);
}

// Hook to use the ComplianceContext in a component
function useCompliance() {
	const context = useContext(ComplianceContext);
	if (context === undefined) {
		throw new Error(
			"useCompliance must be used within a ComplianceProvider"
		);
	}
	return context;
}

export { ComplianceProvider, useCompliance };
