import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { useSymphonyApiService } from '../../../hooks/useSymphonyApi';

const FactsContext = createContext();

function FactsProvider({ children }) {

    const apiServiceClient = useSymphonyApiService();

    const [facts, setFacts] = useState([]);
    const [loadingFacts, setLoadingFacts] = useState(false);
    const [error, setError] = useState(null);

    const loadFacts = async () => {
        setLoadingFacts(true);
        try {
            const data = await apiServiceClient.Facts.getAllFacts();
            setFacts(data);
        } catch (err) {
            setError(err);
        } finally {
            setLoadingFacts(false);
        }
    };

    const createFact = async (fact) => {
        try {
            const newFact = await apiServiceClient.Facts.createFact(fact);
            setFacts([newFact, ...facts]);
        } catch (err) {
            setError(err);
        }
    };

    const updateFact = async (id, updatedFact) => {
        try {
            const result = await apiServiceClient.Facts.updateFact(id, updatedFact);
            setFacts(facts.map(f => f.id === id ? result : f));
        } catch (err) {
            setError(err);
        }
    };

    const deleteFact = async (id) => {
        try {
            await apiServiceClient.Facts.deleteFact(id);
            setFacts(facts.filter(f => f.id !== id));
        } catch (err) {
            setError(err);
        }
    };

    // Load facts when the provider is mounted
    useEffect(() => {
        loadFacts();
    }, []);

    return (
        <FactsContext.Provider value={{ facts, loadingFacts, error, createFact, updateFact, deleteFact }}>
            {children}
        </FactsContext.Provider>
    );
}

function useFacts() {
    const context = useContext(FactsContext);
    if (context === undefined) {
        throw new Error('useFacts must be used within a FactsProvider');
    }
    return context;
}

export { FactsProvider, useFacts };