import { createContext, useState, useContext, useEffect } from "react";
import { useAuthentication } from "../../../contexts/AuthContext";
import * as signalR from "@microsoft/signalr";
import { signalRConfig } from "../../../configs/signalRConfig";

const SignalRContext = createContext();

function SignalRProvider({ children }) {
	const { apiAccessToken } = useAuthentication();

    const [mappingMethods, setMappingMethods] = useState([]);
	const [hubConnect, setHubConnect] = useState(null);

	useEffect(() => {
		initializeSignalR();
	}, [])

    useEffect(() => {
		if (hubConnect) {
			connectSignalR();
			mapMethods();
		}

	}, [mappingMethods, hubConnect])

	const initializeSignalR = () => {
		setHubConnect(new signalR.HubConnectionBuilder()
			.withUrl(`${signalRConfig.baseHubUrl}chat`, {
				accessTokenFactory: () => apiAccessToken,
			})
			.withAutomaticReconnect()
			.build());
		addMappingMethod();
	}

	const connectSignalR = (transport = null) => {
		const connectionMethod = transport ?? signalRConfig.overrideConnection ?? ['webSockets', 'longPolling', 'serverSentEvents', 'foreverFrame']
		try {
			console.log(`[SIGR] Attempting to connect signalR with connection method: ${connectionMethod}`)
			hubConnect.start({ transport: connectionMethod}).then(() => {
				console.log("[SIGR] Connection successful!");
			});
		} catch (error) {
			console.log(
				"[SIGR] Error while establishing connection with the SignalRChatHub" +
				error.message +
				"Connection Method: " + connectionMethod
			);
			if (!transport) connectSignalR('longPolling');
		}
		
	}

	const addMappingMethod = (mapping) => {
		if(mapping) mappingMethods.push(mapping);
	}

	const mapMethods = () => {
		mappingMethods.forEach((methodDef) => {
			//Validating the method definitions
			if (
				methodDef &&
				typeof methodDef?.TextName === "string" &&
				typeof methodDef?.Method === "function"
			) {
				hubConnect?.on(methodDef?.TextName, (param1) => {
					console.log(`[SIGNALR] Received SignalR notification mapped to ${methodDef?.TextName}`)
					methodDef.Method(param1);
				});
			} else {
				console.log("[SIGNALR] Failed to map a method definition");
			}
		})
	}

	return (
		<SignalRContext.Provider
			value={{
                // States

                // Functions
                addMappingMethod,
				connectSignalR
			}}
		>
			{children}
		</SignalRContext.Provider>
	);
}

// Hook to use the SignalRContext in a component
function useSignalR() {
	const context = useContext(SignalRContext);
	if (context === undefined) {
		throw new Error(
			"useSignalR must be used within a SignalRProvider"
		);
	}
	return context;
}

export { SignalRProvider, useSignalR };
